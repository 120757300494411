<template lang="pug">
.section
  div User
  b-table(bordered hoverable narrowed striped v-bind:data="[firebaseUser]" v-bind:mobile-cards="false" v-if="firebaseUser")
    b-table-column(width="20")
      template(v-slot:header)
        b-icon(icon="circle")
      template(v-slot="props")
        b-icon(icon="circle" v-bind:type="props.row.status === 'online' ? 'is-primary' : 'is-danger'")
    b-table-column(label="Name")
      template(v-slot="props")
        span {{props.row.name}}
        span.is-size-7(v-if="props.row.game") &nbsp; {{props.row.game}}
    b-table-column(width="90")
      template(v-slot:header)
        span
      template(v-slot="props")
        span(v-on:click="clickUpdateUserName")
          b-tooltip(label="Update User Name" type="is-dark")
            b-icon.pointer(icon="pen" type="is-primary")
        span(v-if="firebaseUser.game === null" v-on:click="clickPlayGame")
          b-tooltip(label="Play Game" type="is-dark")
            b-icon.pointer(icon="play" type="is-primary")
        span(v-if="firebaseUser.game === null" v-on:click="clickJoinGame")
          b-tooltip(label="Join Game" type="is-dark")
            b-icon.pointer(icon="user-plus" type="is-primary")
        span(v-if="firebaseUser.game !== null && firebaseUser.game === firebaseUser.id" v-on:click="clickCopyGame")
          b-tooltip(label="Copy Game" type="is-dark")
            b-icon.pointer(icon="copy" type="is-primary")
        span(v-if="firebaseUser.game !== null && firebaseUser.game === firebaseUser.id" v-on:click="clickStopGame")
          b-tooltip(label="Stop Game" type="is-dark")
            b-icon.pointer(icon="stop" type="is-danger")
        span(v-if="firebaseUser.game !== null && firebaseUser.game !== firebaseUser.id" v-on:click="clickExitGame")
          b-tooltip(label="Exit Game" type="is-dark")
            b-icon.pointer(icon="user-times" type="is-danger")
    template(slot="empty")
      .content.has-text-centered.has-text-grey
        b-icon(icon="exclamation" type="is-danger")
  div Users
  b-table(bordered hoverable narrowed striped v-bind:data="firebaseUsers" v-bind:mobile-cards="false" v-if="firebaseUsers")
    b-table-column(width="20")
      template(v-slot:header)
        b-icon(icon="circle")
      template(v-slot="props")
        b-icon(icon="circle" v-bind:type="props.row.status === 'online' ? 'is-primary' : 'is-danger'")
    b-table-column(label="Name")
      template(v-slot="props")
        span {{props.row.name}}
        span.is-size-7(v-if="props.row.game") &nbsp; {{props.row.game}}
    b-table-column(width="90")
      template(v-slot:header)
        span
      template(v-slot="props")
        span
    template(slot="empty")
      .content.has-text-centered.has-text-grey
        b-icon(icon="exclamation" type="is-danger")
  div Games
  b-table(bordered hoverable narrowed striped v-bind:data="firebaseGames" v-bind:mobile-cards="false" v-if="firebaseGames")
    b-table-column(width="20")
      template(v-slot:header)
        b-icon(icon="circle")
      template(v-slot="props")
        b-icon(icon="circle" type="is-primary")
    b-table-column(label="Game")
      template(v-slot="props")
        span {{props.row.id}}
        span.is-size-7 &nbsp; {{gameUsers(props.row.id)}}
    b-table-column(width="90")
      template(v-slot:header)
        span
      template(v-slot="props")
        span
    template(slot="empty")
      .content.has-text-centered.has-text-grey
        b-icon(icon="exclamation" type="is-danger")
</template>

<script>
import JoinGame from '@/components/JoinGame.vue'
import UpdateUserName from '@/components/UpdateUserName.vue'

import FirebaseMixin from '@/mixins/FirebaseMixin'
import ServiceWorkerMixin from '@/mixins/ServiceWorkerMixin'

export default {
  mixins: [
    FirebaseMixin,
    ServiceWorkerMixin
  ],
  mounted () {
    this.firebaseSubscribe()
  },
  beforeUnmount () {
    this.firebaseUnsubscribe()
  },
  methods: {
    async clickCopyGame () {
      navigator.clipboard.writeText(this.firebaseUser.game)
    },
    async clickExitGame () {
      await this.exitGame()
    },
    async clickJoinGame () {
      this.prompt(JoinGame, {
        impl: this.joinGame,
        noun: 'Game',
        verb: 'Join'
      })
    },
    async clickPlayGame () {
      await this.playGame()
    },
    async clickStopGame () {
      await this.stopGame()
    },
    clickUpdateUserName () {
      this.prompt(UpdateUserName, {
        impl: this.updateUserName,
        initialName: this.firebaseUser.name,
        noun: 'User Name',
        verb: 'Update'
      })
    },
    prompt (component, props) {
      this.$buefy.modal.open({
        canCancel: ['escape'],
        component,
        fullScreen: true,
        hasModalCard: true,
        parent: this,
        props
      })
    }
  }
}
</script>

<style lang="sass">
@import '~@fortawesome/fontawesome-free/css/all.min.css'
@import "~bulma";
@import "~buefy/src/scss/buefy";

.modal-card
  height: 100vh
  max-height: 100vh

.modal-card-foot
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0

.modal-card-head
  border-top-left-radius: 0
  border-top-right-radius: 0

.pointer
  cursor: pointer

.section
  padding: 1rem

.b-table .table th .th-wrap
  .icon
    margin: 0
</style>

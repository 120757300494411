import Vue from 'vue'

import buefy from '@/buefy'
import el from '@/el'
import firebase from '@/firebase'
import render from '@/render'
import store from '@/store'

const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  unsubscribe()
  new Vue({ // eslint-disable-line no-new
    buefy,
    el,
    render,
    store
  })
})

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { oneOf, required } from 'vee-validate/dist/rules'

extend('oneOf', { ...oneOf, message: '{_field_} is invalid' })
extend('required', { ...required, message: '{_field_} is required' })

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  }
}
</script>

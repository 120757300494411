<template lang="pug">
ValidationObserver(slim v-slot="{invalid}")
  form(v-on:submit.prevent)
    .modal-card(style="width: auto")
      .modal-card-head
        .modal-card-title {{verb}} {{noun}}
      .modal-card-body
        .field
          .label Game
          .control.is-expanded
            ValidationProvider(v-bind:rules="rules" v-slot="{errors}")
              input.input(v-bind:class="[errors.length ? 'is-danger' : 'is-primary']" name="Game" placeholder="Game" type="text" v-model.trim="game")
              .help.is-danger(v-for="error in errors") {{error}}
      .modal-card-foot
        button.button.has-text-weight-semibold(v-on:click="$parent.close()") Cancel
        button.button.has-text-weight-semibold(class="is-primary" v-bind:disabled="invalid" v-on:click="$parent.close(); impl(game)") {{verb}} {{noun}}
</template>

<script>
import FirebaseMixin from '@/mixins/FirebaseMixin.vue'
import FormMixin from '@/mixins/FormMixin.vue'

export default {
  mixins: [
    FirebaseMixin,
    FormMixin
  ],
  props: {
    impl: {
      required: true,
      type: Function
    },
    noun: {
      required: true,
      type: String
    },
    verb: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      game: null
    }
  },
  computed: {
    rules () {
      return 'required|oneOf:' + this.firebaseGames.map((firebaseGame) => firebaseGame.id).join(',')
    }
  }
}
</script>

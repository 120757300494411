import Firebase from 'firebase'

import constants from '@/constants'

export default Firebase.initializeApp({
  apiKey: constants.firebase.apiKey,
  appId: constants.firebase.appId,
  databaseURL: constants.firebase.databaseURL,
  projectId: constants.firebase.projectId
})

<script>
import { make } from 'vuex-pathify'

const state = {
  serviceWorkerIsReloading: false
}

export default {
  mutations: make.mutations(state),
  namespaced: true,
  state
}
</script>

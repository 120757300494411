<script>
import { sync } from 'vuex-pathify'

export default {
  computed: {
    serviceWorkerIsReloading: sync('ServiceWorkerModule/serviceWorkerIsReloading')
  },
  created () {
    this.serviceWorkerRegister()
  },
  methods: {
    serviceWorkerRegister () {
      if (navigator && navigator.serviceWorker) {
        navigator.serviceWorker.register('firebase-messaging-sw.js').then((registration) => {
          registration.addEventListener('updatefound', () => {
            const installingWorker = registration.installing
            installingWorker.addEventListener('statechange', () => {
              if (installingWorker.state === 'installed') {
                registration.waiting.postMessage({ action: 'skipWaiting' })
              }
            })
          })
        })
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (!this.serviceWorkerIsReloading) {
            this.serviceWorkerIsReloading = true
            window.location.reload()
          }
        })
      }
    }
  }
}
</script>

<template lang="pug">
ValidationObserver(slim v-slot="{invalid}")
  form(v-on:submit.prevent)
    .modal-card(style="width: auto")
      .modal-card-head
        .modal-card-title {{verb}} {{noun}}
      .modal-card-body
        .field
          .label Name
          .control.is-expanded
            ValidationProvider(rules="required" v-slot="{errors}")
              input.input(v-bind:class="[errors.length ? 'is-danger' : 'is-primary']" name="Name" placeholder="Name" type="text" v-model.trim="name")
              .help.is-danger(v-for="error in errors") {{error}}
      .modal-card-foot
        button.button.has-text-weight-semibold(v-on:click="$parent.close()") Cancel
        button.button.has-text-weight-semibold(class="is-primary" v-bind:disabled="invalid" v-on:click="$parent.close(); impl(name)") {{verb}} {{noun}}
</template>

<script>
import FormMixin from '@/mixins/FormMixin.vue'

export default {
  mixins: [
    FormMixin
  ],
  props: {
    impl: {
      required: true,
      type: Function
    },
    initialName: {
      required: true,
      type: String
    },
    noun: {
      required: true,
      type: String
    },
    verb: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      name: null
    }
  },
  mounted () {
    this.name = this.initialName
  }
}
</script>

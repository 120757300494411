<script>
import { make } from 'vuex-pathify'
import { firestoreAction } from 'vuexfire'

import firebase from '@/firebase'

const actions = {
  bindGames: firestoreAction(async (context) => {
    await context.bindFirestoreRef('firebaseGames', firebase.firestore().collection('games'))
  }),
  bindUser: firestoreAction(async (context) => {
    await context.bindFirestoreRef('firebaseUser', firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid))
  }),
  bindUsers: firestoreAction(async (context) => {
    await context.bindFirestoreRef('firebaseUsers', firebase.firestore().collection('users'))
  }),
  deleteGame: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('games').doc(payload.game).delete()
  }),
  deleteGameUser: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('games').doc(payload.game).collection('users').doc(payload.user).delete()
  }),
  insertGame: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('games').doc(payload.game).set({})
  }),
  insertGameUser: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('games').doc(payload.game).collection('users').doc(payload.user).set({})
  }),
  insertUser: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).set({
      name: payload.name,
      game: null,
      status: 'online'
    })
  }),
  unbindGames: firestoreAction(async (context) => {
    await context.unbindFirestoreRef('firebaseGames')
  }),
  unbindUser: firestoreAction(async (context) => {
    await context.unbindFirestoreRef('firebaseUser')
  }),
  unbindUsers: firestoreAction(async (context) => {
    await context.unbindFirestoreRef('firebaseUsers')
  }),
  updateUserGame: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
      game: payload.game
    })
  }),
  updateUserName: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
      name: payload.name
    })
  }),
  updateUserStatus: firestoreAction(async (context, payload) => {
    await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
      status: payload.status
    })
  })
}

const state = {
  firebaseGames: null,
  firebaseUnsubscribe: null,
  firebaseUser: null,
  firebaseUsers: null
}

export default {
  actions,
  mutations: make.mutations(state),
  namespaced: true,
  state
}
</script>

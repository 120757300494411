<script>
import { get, sync } from 'vuex-pathify'

import firebase from '@/firebase'

export default {
  computed: {
    firebaseGames: get('FirebaseModule/firebaseGames'),
    firebaseUnsubscribe: sync('FirebaseModule/firebaseUnsubscribe'),
    firebaseUser: get('FirebaseModule/firebaseUser'),
    firebaseUsers: get('FirebaseModule/firebaseUsers')
  },
  methods: {
    firebasePresence () {
      firebase.database().ref('.info/connected').on('value', async (snapshot) => {
        if (snapshot.val()) {
          const user = firebase.database().ref('users').child(firebase.auth().currentUser.uid)
          user.onDisconnect().set({ status: 'offline' }).then(async () => {
            user.set({ status: 'online' })
            await this.$store.dispatch('FirebaseModule/updateUserStatus', { status: 'online' })
          })
        } else {
          await this.$store.dispatch('FirebaseModule/updateUserStatus', { status: 'offline' })
        }
      })
    },
    firebaseSubscribe () {
      this.firebaseUnsubscribe = firebase.auth().onAuthStateChanged((user) => {
        this.handleAuthStateChanged(user)
      })
    },
    async handleAuthStateChanged (user) {
      if (user !== null) {
        this.firebasePresence()
        await this.$store.dispatch('FirebaseModule/bindGames')
        await this.$store.dispatch('FirebaseModule/bindUsers')
        if (this.firebaseUsers.find((firebaseUser) => firebaseUser.id === user.uid) === undefined) {
          await this.$store.dispatch('FirebaseModule/insertUser', {
            name: 'Anonymous'
          })
        }
        await this.$store.dispatch('FirebaseModule/bindUser')
      } else {
        await firebase.auth().signInAnonymously()
      }
    },
    async exitGame () {
      await this.$store.dispatch('FirebaseModule/deleteGameUser', {
        game: this.firebaseUser.game,
        user: this.firebaseUser.id
      })
      await this.$store.dispatch('FirebaseModule/updateUserGame', {
        game: null
      })
    },
    gameUsers (game) {
      return this.firebaseUsers ? this.firebaseUsers.filter((firebaseUser) => firebaseUser.game === game).map((firebaseUser) => firebaseUser.name).join(', ') : []
    },
    async joinGame (game) {
      await this.$store.dispatch('FirebaseModule/insertGameUser', {
        game: game,
        user: this.firebaseUser.id
      })
      await this.$store.dispatch('FirebaseModule/updateUserGame', {
        game: game
      })
    },
    async playGame () {
      await this.$store.dispatch('FirebaseModule/insertGame', {
        game: this.firebaseUser.id
      })
      await this.$store.dispatch('FirebaseModule/insertGameUser', {
        game: this.firebaseUser.id,
        user: this.firebaseUser.id
      })
      await this.$store.dispatch('FirebaseModule/updateUserGame', {
        game: this.firebaseUser.id
      })
    },
    async stopGame () {
      await this.$store.dispatch('FirebaseModule/deleteGameUser', {
        game: this.firebaseUser.id,
        user: this.firebaseUser.id
      })
      await this.$store.dispatch('FirebaseModule/deleteGame', {
        game: this.firebaseUser.id
      })
      await this.$store.dispatch('FirebaseModule/updateUserGame', {
        game: null
      })
    },
    async updateUserName (name) {
      await this.$store.dispatch('FirebaseModule/updateUserName', {
        name
      })
    },
    async updateUserStatus (status) {
      await this.$store.dispatch('FirebaseModule/updateUserStatus', {
        status
      })
    }
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPathify from 'vuex-pathify'
import { vuexfireMutations } from 'vuexfire'

import FirebaseModule from '@/modules/FirebaseModule'
import ServiceWorkerModule from '@/modules/ServiceWorkerModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    FirebaseModule,
    ServiceWorkerModule
  },
  mutations: {
    ...vuexfireMutations
  },
  plugins: [
    VuexPathify.plugin
  ],
  strict: process.env.NODE_ENV !== 'production'
})
